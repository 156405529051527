export default function MyAccess(resp) {
    var access = false;
    console.log("resp.name is " + resp.name);
    console.log("resp.sub is " + resp.sub);
    // payload.name == 'Marc Racine' ? setAccess(true) : setAccess();
    // payload.name == 'Marc Racine' ? console.log('yes') : console.log('no');

    if ((resp.email === 'lucp2021perso@gmail.com' && resp.sub === '108571631513224067349')     ||
        (resp.name === 'Eric L'           && resp.sub === '114565629369585878595')     ||
        (resp.name === 'Jonathan L'       && resp.sub === '106344627984498679493')     ||
        (resp.name === 'Jeremie M'        && resp.sub === '111712325187761128723')     ||
        (resp.name === 'Pierre Major'     && resp.sub === '108969854261031931639')     ||
        (resp.name === 'Jf Arguin'        && resp.sub === '115509592163600045442')     ||
        (resp.name === 'Nikolas Baril'    && resp.sub === '118344011435226799114')     ||
        (resp.name === 'Bob Renaud'       && resp.sub === '100256252400818696520')     ||
        (resp.name === 'Benoit Massie'    && resp.sub === '112031915606976421024')     ||
        (resp.name === 'JF Levaque'       && resp.sub === '115991390534800965718')     ||
        (resp.name === 'Annie Desjardins' && resp.email === 'annie.desjardins10@gmail.com' ) ||
        (resp.name === 'Marc Racine'      && resp.sub === '117500737990184886771')) {
   
       var access = true;
    } 

  console.log("access is " + access);
  return access;
};


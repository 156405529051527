import React, { useState ,useRef } from 'react';
import { useScript } from './hooks/useScript';
import jwt_decode from "jwt-decode";
import MyChart from './MyChart';
import MyAccess from './MyAccess';
import MyPaye from './MyPaye';

const App = () => {
  const googlebuttonref = useRef();

  const [user, setUser] = useState(false);
  const [access, setAccess] = useState(false);
  const [paye, setPaye] = useState(false);

  const onGoogleSignIn = (user, access) => {
    let userCred = user.credential;
    let payload = jwt_decode(userCred);
    setUser(payload);
    console.log(payload);
    setAccess(MyAccess(payload));
    setPaye(MyPaye);
  };

  useScript("https://accounts.google.com/gsi/client", () => {
    window.google.accounts.id.initialize({
      // For local dev
      // client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      // For rouge.homeip.net
      client_id: "793293841566-gvc9e3judu2jqouu3tcplh42h3hg0oq9.apps.googleusercontent.com",

      callback: onGoogleSignIn,
      auto_select:false,
    });

    window.google.accounts.id.renderButton(googlebuttonref.current, {
      size: "medium",
    });
  });

  return (
    <body>
    <div
      align="center"
      class="App"
      style={{
//        display: "flex",
//        justifyContent: "center",
//        alignItems: "center",
//        height: "100vh",
      }}
    > 
      {!user && <div ref={googlebuttonref}></div>}
      { user && (
        <div align="center">
          {user.name} | {user.email} | {user.sub}
          <p><img src={user.picture} alt="profile" /></p>
          <button
            onClick={() => {
              setUser(false);
              setAccess();
            }}
          >Logout</button>
        </div>
      )}
      { access &&
        <p>{user.given_name}, tu as accès</p>
      }
      { !access &&
        <p>Tu N'as PAS accès</p>
      }
      { access &&
        <MyChart />
      }
      { access &&
        <MyPaye />
      }
    </div>
    </body>
  );
};

export default App

// from https://www.youtube.com/watch?v=uzvvEgqXUhc&t=611s&ab_channel=ARIS_dev

import React, { useState, useEffect } from 'react';
import Chart from 'react-google-charts';

//const dateTicks = [
//  new Date('2022-02-15T12:00:00'),
//  new Date('2022-02-16T00:00:00'),
//  new Date('2022-02-16T12:00:00'),
//  new Date('2022-02-17T00:00:00'),
//  new Date('2022-02-17T12:00:00')
//];

const optionsVent={
        title: 'Vitesse',
        //chartArea: { width: '50%' },
        explorer: {},
        series: [{targetAxisIndex:0},{targetAxisIndex:1}],
        lineSize: 1,
        vAxis: {
          title: 'Knots',
          titleTextStyle: {color: 'red'},
          minorGridlines: {count: 4},
        },
        hAxis: {
          title: 'Time',
          //minValue: 0,
          titleTextStyle: {color: 'red'},
	        //gridlines: { count: 10 },
	        minorGridlines: {count: 2},
          //format: "dd-MMM HH:MM",
          //format: "dd-MMM HH:MM",
          //ticks: dateTicks
        },
}

const optionsDirection={
        title: 'Direction',
        //chartArea: { width: '50%' },
        explorer: {},  //Zoom
        lineSize: 1,
        legend: {position: 'right'},
        //series: [{targetAxisIndex:0},{targetAxisIndex:1}],
        series: [{lineWidth:1},{targetAxisIndex:1}],
        vAxis: {
          title: 'Bearing',
          titleTextStyle: {color: 'red'},
          textStyle: {fontName: 'courier', bold: 'true'},
          ticks: [{v:0,f:'N____0'},{v:45,f:'NE__45'},{v:90,f:'E___90'},{v:135,f:'SE_135'},{v:180,f:'S__180'},{v:225,f:'SW_225'},{v:270,f:'W__270'},{v:315,f:'NW_315'},{v:360,f:'N__360'}],
          minorGridlines: {count: 3},
        },
        hAxis: {
          title: 'Time',
          //minValue: 0,
          titleTextStyle: {color: 'red'},
          baselineColor: 'black',
	        //gridlines: { count: 10 },
	        minorGridlines: {count: 2},
          //format: "dd-MMM HH:MM",
          //ticks: dateTicks
        },
}

const MyChart = () => {

  const [filteredDataVent, setFilteredDataVent] = useState([]);
  const [filteredDataDirect, setFilteredDataDirect] = useState([]);
  const [lastUpdate, setLastUpdate] = useState([]);
  const [lastAvg, setLastAvg] = useState([]);
  const [lastGust, setLastGust] = useState([]);
  const [highestGust, setHighestGust] = useState(0);

  const fetchData = async () => {
    try {
      const response = await fetch('data.json');
      const data = await response.json(); 
      // console.log('MR data.chartData', data.chartData);
      // console.log('my data', data.chartData[data.chartData.length -1]);
      let text = data.chartData[data.chartData.length -1][0]
      let dateTime = text.match(/Date\((\d+),(\d+),(\d+),(\d+),(\d+),(\d+)/);
      let yr = dateTime[1];
      let mo = dateTime[2];
      let dy = dateTime[3];
      let hh = dateTime[4];
      let mm = dateTime[5];
      let ss = dateTime[6];
      let newDateTime = `${yr}-${mo}-${dy} ${hh}:${mm}:${ss}`;
      setLastUpdate(newDateTime);
      setLastAvg(data.chartData[data.chartData.length -1][1]);
      setLastGust(data.chartData[data.chartData.length -1][3]);
      // Update the chart data and options with the new data
      //    0                           1   2      3   4      5    6   7   
      // ["Date(2023,02,17,11,19,59)",6.1,"test",11.3,"test2",null,333,"Feb 17,11:19\n123"],
      setFilteredDataVent(data.chartData.map(row => row.filter((value, index) =>                  index !== 5 && index !== 6 && index !== 7)));
      // console.log('MR filteredDataVent', filteredDataVent);
      setFilteredDataDirect(data.chartData.map(row => row.filter((value, index) => index === 0 || index === 5 || index === 6 || index === 7)));
      // console.log('MR filteredDataDirect', filteredDataDirect);

      let max = 0;
      for (let i = 0; i < data.chartData.length; i++) {
        const gustValue = data.chartData[i][3];
        // console.log('max:', max,  'gustValue:', gustValue);
        if (gustValue > max) {
          max = gustValue;
        }
      }
      setHighestGust(max);

    } catch (error) {console.log(error)};
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleClick = () => {
    fetchData();
  };

  return (
    <div>
      <button onClick={handleClick}>Update Chart</button>
      <p>Dernière mise-à-jour: <br /><b>{lastUpdate}</b></p>
      <p><b>Avg: {lastAvg}Kt / Gust: {lastGust}Kt</b></p>
      <p>Max Gust: {highestGust}Kt</p>
      <Chart
        width={'1200px'}
        height={'400px'}
        chartType="LineChart"
        loader={<div>Loading Chart</div>}
        data={null}
        columns={[
          { type: "date", label: "Day" },
          { type: "number", label: "Avg" },
          { type: "string", role: "tooltip" },
          { type: "number", label: "Gust" },
          { type: "string", role: "tooltip" }
        ]}
        rows={filteredDataVent}
        {...optionsVent.vAxis.ticks = highestGust > 20 ? [0,10,20,30,40] : [0,5,10,15,20] }
        options={optionsVent}
        legendToggle
      />
      <Chart
        width={'1200px'}
        height={'400px'}
        chartType="LineChart"
        loader={<div>Loading Chart</div>}
        data={null}
        columns={[
          { type: "date", label: "Day" },
          { type: "number", label: null },
          { type: "number", label: "Dir" },
          { type: "string", role: "tooltip" }
        ]}
        rows={filteredDataDirect}      
        options={optionsDirection}
        legendToggle
      />
    </div>
  );
};

export default MyChart;

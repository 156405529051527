import React, { useState ,useRef } from 'react';

// const noms = "Marco";
// export default noms;

export default function MyPaye() {
   return (
      <>
         <div id="message" 
              align="left"
              style={{
                  //display: "flex",
                  justifyContent: "left",
                  //alignItems: "center",
                  //height: "100vh",
               }}
         >
         Logs:<br/> 
         <p>Payé pour 2024: Nouveau repeater à 573.68$ + 60$ de batteries: Pierre(50$), Luc(50$), Eric(50$), Nikolas(50$)<br />
            Payé pour 2023: Pierre, Jonathan, Benoit (Tuiles) <br />
            Payé pour 2022: Nikolas, Jonathan (Batteries), JF Levaque (à Eric), Pierre (à Eric), Benoit, Bob (à Eric) <br />
            Payé pour 2021: Jean-Francois, Jean-François_camping (à Eric), Jérémie, Benoit(wetsuit), Pierre, Jonathan (filtre bateau), Bob (3x20 à Eric), Nikolas <br />
            Payé pour 2020: Jean-Francois(2x20), Nikolas<br />
            Payé pour 2019: Nikolas, Patrick, Pierre-Simon, Jonathan <br />
            Payé pour 2018: Francois, Sylvain(Jos), Bob, Pierre-Simon, Patrick, Nikolas, Jonathan <br />
            Payé anémomètre fin 2017: Francois, Jonathan, Nikolas, Sylvain(Jos), Bob, Pierre-Simon, Patrick <br />
            Payé pour 2017: Francois, Pierre-Simon, Sebastien, Sylvain(Jos), Jean-Francois, Nikolas, Jonathan <br />
            Payé pour 2016: Nikolas, Francois, SylvainJos(20+10), Jean-Francois, Pierre-Simon, Sylvain, Jonathan <br />
            Payé pour 2015: Nikolas, Pierre-Simon, Francois, Bob, Sylvain(Jos)2x20, Jean-Francois2x20+10, Sylvain et Jos. Fin 2015: Marc gardé 50$, le reste à Eric</p>
         </div>
      </>
   );
};
